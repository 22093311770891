import { useState, useMemo } from 'react';
import JobCard from '../components/JobCard';
import SearchBar from '../components/SearchBar';
import JobFilters from '../components/JobFilters';
import Pagination from '../components/Pagination';
import FeaturedJobs from '../components/FeaturedJobs';
import { api } from '../services/api';
import { useQuery } from '@tanstack/react-query';
import { Benefit } from '@/types';
import { XMarkIcon } from '@heroicons/react/24/outline';
const ITEMS_PER_PAGE = 10;

export default function Companies() {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [filters, setFilters] = useState({
    categories: [] as string[],
    benefitTypes: [] as string[],
  });
  const [showFilters, setShowFilters] = useState(false);

  // Fetch companies using React Query
  const { data: companies, isLoading, error } = useQuery({
    queryKey: ['companies'],
    queryFn: api.companies.getAll,
  });

  console.log('companies', companies);

  // Get featured companies (first 3)
  const featuredCompanies = useMemo(() => 
    companies?.slice(0, 3) ?? [], 
    [companies]);

  // Apply filters and search
  const filteredCompanies = useMemo(() => {
    if (!companies) return [];
    
    let filtered = [...companies];

    // Apply search
    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      filtered = filtered.filter(company => 
        company.name.toLowerCase().includes(query) ||
        company.category.toLowerCase().includes(query) ||
        company.benefits.some((benefit: Benefit) => 
          benefit.type.toLowerCase().includes(query) ||
          benefit.value.toLowerCase().includes(query)
        )
      );
    }

    // Apply category filters
    if (filters.categories.length > 0) {
      filtered = filtered.filter(company => 
        filters.categories.includes(company.category)
      );
    }

    // Apply benefit type filters
    if (filters.benefitTypes.length > 0) {
      filtered = filtered.filter(company =>
        company.benefits.some((benefit: Benefit) => 
          filters.benefitTypes.includes(benefit.type)
        )
      );
    }

    return filtered;
  }, [searchQuery, filters, companies]);

  // Calculate pagination
  const totalPages = Math.ceil(filteredCompanies.length / ITEMS_PER_PAGE);
  const paginatedCompanies = useMemo(() => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    return filteredCompanies.slice(startIndex, startIndex + ITEMS_PER_PAGE);
  }, [filteredCompanies, currentPage]);

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const handleFilterChange = (newFilters: typeof filters) => {
    setFilters(newFilters);
    setCurrentPage(1);
  };

  if (isLoading) {
    return (
      <div className="p-8 text-center">
        <div className="text-white">Loading companies...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-8 text-center">
        <div className="text-red-500">Error loading companies. Please try again later.</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen p-4 lg:p-8 pt-20 lg:pt-8">
      <div className="max-w-7xl mx-auto">
        <div className="mb-6 lg:mb-8">
          <h1 className="text-2xl lg:text-3xl font-bold text-white mb-4">Browse Companies</h1>
          <SearchBar onSearch={handleSearch} />
        </div>

        <div className="lg:grid lg:grid-cols-[300px,1fr] lg:gap-8">
          {/* Mobile Filters Button */}
          <div className="lg:hidden mb-4">
            <button
              onClick={() => setShowFilters(!showFilters)}
              className="w-full bg-gray-800 text-white p-3 rounded-md flex items-center justify-center space-x-2"
            >
              <span>Filters</span>
            </button>
          </div>

          {/* Mobile Filters Modal */}
          {showFilters && (
            <div className="fixed inset-0 z-50 bg-gray-900 bg-opacity-75 flex items-start justify-center pt-16 px-4">
              <div className="bg-gray-800 p-4 rounded-lg w-full max-w-md max-h-[80vh] overflow-y-auto">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-white font-bold">Filters</h2>
                  <button 
                    onClick={() => setShowFilters(false)}
                    className="p-2 text-gray-400 hover:text-white"
                  >
                    <XMarkIcon className="h-6 w-6" />
                  </button>
                </div>
                <JobFilters onFilterChange={handleFilterChange} />
              </div>
            </div>
          )}

          {/* Desktop Filters */}
          <div className="hidden lg:block sticky top-8">
            <JobFilters onFilterChange={handleFilterChange} />
          </div>

          {/* Companies List */}
          <div className="w-full">
            {filteredCompanies.length > 0 ? (
              <>
                <div className="grid gap-4 mb-6">
                  {paginatedCompanies.map((company) => (
                    <JobCard key={company.name} company={company} />
                  ))}
                </div>
                
                {totalPages > 1 && (
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={setCurrentPage}
                  />
                )}
              </>
            ) : (
              <div className="text-center py-12 bg-gray-800 rounded-lg">
                <p className="text-gray-400">No companies match your current filters.</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
