import { useState } from 'react';
import { FunnelIcon } from '@heroicons/react/24/outline';

interface FilterProps {
  onFilterChange: (filters: {
    categories: string[];
    benefitTypes: string[];
  }) => void;
}

export default function JobFilters({ onFilterChange }: FilterProps) {
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedBenefitTypes, setSelectedBenefitTypes] = useState<string[]>([]);

  const categories = ['A', 'M', 'O', 'S', 'W', 'E', 'P']; // From company categories
  const benefitTypes = [
    'annual_leave',
    'parental_leave',
    'paternity_leave',
    'maternity_leave',
    'medical',
    'special_leave',
    'special_benefits',
    'epf_contribution',
    'flexible_benefit',
    'flexible_spending',
    'work_arrangement',
    'car_loan',
    'perks',
    'office_perks',
    'travel_benefits',
    'learning_benefits',
    'professional_development'
  ];

  const handleCategoryChange = (category: string) => {
    const updated = selectedCategories.includes(category)
      ? selectedCategories.filter(c => c !== category)
      : [...selectedCategories, category];
    setSelectedCategories(updated);
    updateFilters(updated, selectedBenefitTypes);
  };

  const handleBenefitTypeChange = (type: string) => {
    const updated = selectedBenefitTypes.includes(type)
      ? selectedBenefitTypes.filter(t => t !== type)
      : [...selectedBenefitTypes, type];
    setSelectedBenefitTypes(updated);
    updateFilters(selectedCategories, updated);
  };

  const updateFilters = (categories: string[], benefitTypes: string[]) => {
    onFilterChange({ categories, benefitTypes });
  };

  const formatBenefitType = (type: string) => {
    return type.split('_').map(word => 
      word.charAt(0).toUpperCase() + word.slice(1)
    ).join(' ');
  };

  return (
    <div className="bg-gray-800 p-6 rounded-lg mb-6">
      <div className="flex items-center mb-4">
        <FunnelIcon className="h-5 w-5 text-green-400 mr-2" />
        <h2 className="text-lg font-semibold text-white">Filters</h2>
      </div>

      {/* Category Filters */}
      <div className="mb-6">
        <h3 className="text-sm font-medium text-gray-400 mb-3">Category</h3>
        <div className="flex flex-wrap gap-2">
          {categories.map(category => (
            <button
              key={category}
              onClick={() => handleCategoryChange(category)}
              className={`px-3 py-1 rounded-full text-sm ${
                selectedCategories.includes(category)
                  ? 'bg-green-500 text-white'
                  : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
              }`}
            >
              {category}
            </button>
          ))}
        </div>
      </div>

      {/* Benefit Type Filters */}
      <div className="mb-6">
        <h3 className="text-sm font-medium text-gray-400 mb-3">Benefits</h3>
        <div className="flex flex-wrap gap-2">
          {benefitTypes.map(type => (
            <button
              key={type}
              onClick={() => handleBenefitTypeChange(type)}
              className={`px-3 py-1 rounded-full text-sm ${
                selectedBenefitTypes.includes(type)
                  ? 'bg-green-500 text-white'
                  : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
              }`}
            >
              {formatBenefitType(type)}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
