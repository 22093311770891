import { Company } from '@/types';
import { BookmarkIcon as BookmarkOutline } from '@heroicons/react/24/outline';
import { BookmarkIcon as BookmarkSolid } from '@heroicons/react/24/solid';
import { useState } from 'react';
import { Link } from 'react-router-dom';

interface JobCardProps {
  company: Company;
  onSaveToggle?: (company: Company) => void;
  isSaved?: boolean;
}

const colors = ['bg-blue-600', 'bg-green-600', 'bg-red-600', 'bg-purple-600', 'bg-yellow-600', 'bg-pink-600'];

export default function JobCard({ company, onSaveToggle, isSaved: propIsSaved }: JobCardProps) {
  const [localIsSaved, setLocalIsSaved] = useState(() => {
    const saved = localStorage.getItem('savedCompanies');
    if (saved) {
      const companies = JSON.parse(saved);
      return companies.some((c: Company) => c.id === company.id);
    }
    return false;
  });

  // Use prop if provided, otherwise use local state
  const isSaved = propIsSaved !== undefined ? propIsSaved : localIsSaved;

  const handleSaveToggle = (e: React.MouseEvent) => {
    e.preventDefault();
    
    const saved = localStorage.getItem('savedCompanies');
    let companies: Company[] = saved ? JSON.parse(saved) : [];
    
    if (isSaved) {
      companies = companies.filter((c: Company) => c.id !== company.id);
    } else {
      companies.push(company);
    }
    
    localStorage.setItem('savedCompanies', JSON.stringify(companies));
    setLocalIsSaved(!isSaved);
    
    if (onSaveToggle) {
      onSaveToggle(company);
    }
  };

  // Get deterministic color based on company name
  const colorIndex = company.name.split('').reduce((acc: number, char: string) => acc + char.charCodeAt(0), 0) % colors.length;

  return (
    <div className="bg-gray-800 p-6 rounded-lg hover:bg-gray-700 transition-colors group">
      <Link to={`/companies/${company.id}`} className="block">
        <div className="flex items-start justify-between">
          <div className="flex space-x-4">
            <div className={`w-12 h-12 rounded flex items-center justify-center ${colors[colorIndex]}`}>
              <span className="text-white font-bold">
                {company.name.split(' ').map(word => word[0]).join('')}
              </span>
            </div>
            <div>
              <h3 className="text-white font-semibold group-hover:text-green-400 transition-colors">
                {company.name}
              </h3>
              <p className="text-gray-400">Category: {company.category}</p>
              {company.benefits.length > 0 && (
                <div className="flex flex-wrap gap-2 mt-2">
                  {company.benefits.slice(0, 2).map((benefit, index) => (
                    <span 
                      key={index}
                      className="text-sm text-gray-400 bg-gray-700 px-2 py-1 rounded"
                    >
                      {benefit.type.split('_').map(word => 
                        word.charAt(0).toUpperCase() + word.slice(1)
                      ).join(' ')}
                    </span>
                  ))}
                  {company.benefits.length > 2 && (
                    <span className="text-sm text-gray-400">
                      +{company.benefits.length - 2} more
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
          <button 
            onClick={handleSaveToggle}
            className="text-gray-400 hover:text-green-400 transition-colors"
          >
            {isSaved ? (
              <BookmarkSolid className="h-6 w-6 text-green-400" />
            ) : (
              <BookmarkOutline className="h-6 w-6" />
            )}
          </button>
        </div>
      </Link>
    </div>
  );
}
