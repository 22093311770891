import { useParams, useNavigate } from 'react-router-dom';
import { BookmarkIcon as BookmarkOutline } from '@heroicons/react/24/outline';
import { BookmarkIcon as BookmarkSolid } from '@heroicons/react/24/solid';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { api } from '../services/api';
import type { Company } from '../types';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';

export default function CompanyDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isSaved, setIsSaved] = useState(false);

  // Fetch company data
  const { data: company, isLoading, error } = useQuery({
    queryKey: ['company', id],
    queryFn: () => api.companies.getById(Number(id)),
    enabled: !!id,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isLoading) {
    return (
      <div className="p-8 text-center">
        <div className="text-white">Loading company details...</div>
      </div>
    );
  }

  if (error || !company) {
    return (
      <div className="p-8 text-center">
        <h1 className="text-2xl font-bold text-white">Company not found</h1>
      </div>
    );
  }

  return (
    <div className="min-h-screen p-4 lg:p-8 pt-36 lg:pt-8">
      <div className="max-w-4xl mx-auto">
        <button
          onClick={() => navigate(-1)}
          className="flex items-center text-gray-400 hover:text-white mb-4 transition-colors fixed lg:relative top-20 left-4 lg:top-auto lg:left-auto z-50 bg-gray-800 lg:bg-transparent px-3 py-2 rounded-md lg:px-0 lg:py-0"
        >
          <ArrowLeftIcon className="h-5 w-5 mr-2" />
          <span>Back</span>
        </button>
        
        <div className="bg-gray-800 rounded-lg p-4 lg:p-8">
          {/* Header */}
          <div className="flex flex-col lg:flex-row lg:items-start lg:justify-between mb-6">
            <div className="flex items-start space-x-4 mb-4 lg:mb-0">
              <div className="w-12 h-12 lg:w-16 lg:h-16 rounded bg-gray-700 flex items-center justify-center">
                <span className="text-lg lg:text-xl font-bold text-white">
                  {company.name.split(' ').map(word => word[0]).join('')}
                </span>
              </div>
              <div className="flex-1">
                <h1 className="text-xl lg:text-2xl font-bold text-white mb-2">{company.name}</h1>
                <p className="text-gray-400 text-sm lg:text-base">Category: {company.category}</p>
              </div>
            </div>
            <button
              onClick={() => setIsSaved(!isSaved)}
              className="text-gray-400 hover:text-green-400 transition-colors"
            >
              {isSaved ? (
                <BookmarkSolid className="h-6 w-6 text-green-400" />
              ) : (
                <BookmarkOutline className="h-6 w-6" />
              )}
            </button>
          </div>

          {/* Benefits */}
          {company.benefits.length > 0 && (
            <div className="mb-8">
              <h2 className="text-xl font-semibold text-white mb-4">Benefits</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {company.benefits.map((benefit, index) => (
                  <div 
                    key={index} 
                    className="bg-gray-700 p-4 rounded-lg"
                  >
                    <h3 className="text-green-400 font-semibold mb-2">
                      {benefit.type.split('_').map(word => 
                        word.charAt(0).toUpperCase() + word.slice(1)
                      ).join(' ')}
                    </h3>
                    <p className="text-gray-300 text-sm lg:text-base">{benefit.value}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
