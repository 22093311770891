import axios from 'axios';
import type { Company } from '../types';

const API_URL = import.meta.env.VITE_API_URL || 'http://localhost:3000';

// create axios instance with CORS credentials
const axiosInstance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Response interceptor for handling errors
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 401) {
      // Handle unauthorized error (e.g., redirect to login)
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export const api = {
  companies: {
    getAll: async (): Promise<Company[]> => {
      const { data } = await axiosInstance.get(`/companies`);
      return data;
    },

    getById: async (id: number): Promise<Company> => {
      const { data } = await axiosInstance.get(`/companies/${id}`);
      return data;
    },

    create: async (company: Omit<Company, 'id'>): Promise<Company> => {
      const { data } = await axiosInstance.post(`/companies`, company);
      return data;
    },

    update: async (id: number, company: Partial<Company>): Promise<void> => {
      await axiosInstance.put(`/companies/${id}`, company);
    },

    delete: async (id: number): Promise<void> => {
      await axiosInstance.delete(`/companies/${id}`);
    }
  }
};
