import { BriefcaseIcon, BookmarkIcon, XMarkIcon, Bars3Icon } from '@heroicons/react/24/outline';
import { Link, useLocation } from 'react-router-dom';
import { useState } from 'react';
import clsx from 'clsx';

interface SidebarProps {
  isOpen: boolean;
  onToggle: () => void;
}

export default function Sidebar({ isOpen, onToggle }: SidebarProps) {
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [subscribed, setSubscribed] = useState(false);

  const navigation = [
    { name: 'Companies', href: '/companies', icon: BriefcaseIcon },
    { name: 'Saved', href: '/saved-companies', icon: BookmarkIcon },
  ];

  const handleSubscribe = (e: React.FormEvent) => {
    e.preventDefault();
    if (email) {
      setSubscribed(true);
      setEmail('');
    }
  };

  return (
    <>
      {/* Mobile Header */}
      <div className="lg:hidden fixed top-0 left-0 right-0 h-16 bg-black z-40 px-4 flex items-center">
        <button
          onClick={onToggle}
          className="p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700"
        >
          {isOpen ? (
            <XMarkIcon className="h-6 w-6" />
          ) : (
            <Bars3Icon className="h-6 w-6" />
          )}
        </button>
        <span className="ml-4 text-white font-bold">MNCMalaysia</span>
      </div>

      {/* Overlay */}
      {isOpen && (
        <div 
          className="lg:hidden fixed inset-0 bg-black bg-opacity-50 z-30"
          onClick={onToggle}
        />
      )}

      {/* Sidebar */}
      <div 
        className={clsx(
          'fixed lg:fixed top-0 left-0 h-screen w-64 bg-black z-40 transform transition-transform duration-300 ease-in-out',
          'lg:transform-none lg:translate-x-0',
          isOpen ? 'translate-x-0' : '-translate-x-full'
        )}
      >
        <div className="flex flex-col h-full overflow-y-auto">
          <div className="p-6 flex-1">
            <Link to="/companies" className="hidden lg:block text-white font-bold text-2xl mb-8">
              MNCMalaysia
            </Link>
            
            <nav className="space-y-4 mb-8">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  onClick={() => onToggle()}
                  className={clsx(
                    'flex items-center space-x-3 text-gray-300 hover:text-white p-2 rounded-md transition-colors',
                    location.pathname === item.href && 'bg-gray-900 text-white'
                  )}
                >
                  <item.icon className="h-6 w-6" />
                  <span>{item.name}</span>
                </Link>
              ))}
            </nav>

            <div className="mt-auto">
              {/* Subscription Form */}
              <div className="pt-6 border-t border-gray-800">
                {subscribed ? (
                  <div className="text-green-400 text-sm">
                    Thanks for subscribing! You'll receive latest company updates soon.
                  </div>
                ) : (
                  <form onSubmit={handleSubscribe} className="space-y-3">
                    <h3 className="text-white text-sm font-medium mb-2">
                      Get MNC Company Updates
                    </h3>
                    <p className="text-gray-400 text-xs mb-4">
                      Subscribe to receive the latest opportunities
                    </p>
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter your email"
                      className="w-full bg-gray-900 text-white px-3 py-2 rounded-md text-sm placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500"
                      required
                    />
                    <button
                      type="submit"
                      className="w-full bg-green-500 hover:bg-green-600 text-white font-medium py-2 px-4 rounded-md text-sm transition-colors"
                    >
                      Subscribe
                    </button>
                  </form>
                )}
              </div>

              {/* Credits */}
              <div className="mt-4 pt-4 border-t border-gray-800">
                <p className="text-gray-400 text-xs text-center">
                  Compiled by{' '}
                  <a 
                    href="https://www.threads.net/@tipswfh" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="text-green-400 hover:text-green-300"
                  >
                    @tipswfh
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
