import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Route, BrowserRouter as Router, Navigate, Routes } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import Companies from "./pages/Companies";
import CompanyDetail from "./pages/CompanyDetail";
import SavedCompanies from "./pages/SavedCompanies";
import { useState } from "react";

const queryClient = new QueryClient();

function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <div className="flex flex-col lg:flex-row bg-gray-900 min-h-screen h-screen font-sans antialiased">
          <Sidebar isOpen={isSidebarOpen} onToggle={() => setIsSidebarOpen(!isSidebarOpen)} />
          
          <main className="flex-1 lg:pl-64 min-h-screen overflow-x-hidden">
            <Routes>
              <Route path="/" element={<Navigate to="/companies" replace />} />
              <Route path="/companies" element={<Companies />} />
              <Route path="/companies/:id" element={<CompanyDetail />} />
              <Route path="/saved-companies" element={<SavedCompanies />} />
            </Routes>
          </main>
        </div>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
