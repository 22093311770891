import { useState, useEffect } from 'react';
import JobCard from '../components/JobCard';
import type { Company } from '../types';

export default function SavedCompanies() {
  const [savedCompanies, setSavedCompanies] = useState<Company[]>([]);

  useEffect(() => {
    const saved = localStorage.getItem('savedCompanies');
    if (saved) {
      setSavedCompanies(JSON.parse(saved));
    }
  }, []);

  const handleSaveToggle = (company: Company) => {
    setSavedCompanies(prev => {
      const newCompanies = prev.filter(c => c.id !== company.id);
      localStorage.setItem('savedCompanies', JSON.stringify(newCompanies));
      return newCompanies;
    });
  };

  return (
    <div className="min-h-screen p-4 lg:p-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-2xl lg:text-3xl font-bold text-white mb-6 lg:mb-8">
          Saved Companies
        </h1>
        
        {savedCompanies.length > 0 ? (
          <div className="grid gap-4">
            {savedCompanies.map((company) => (
              <JobCard 
                key={company.id} 
                company={company} 
                isSaved={true}
                onSaveToggle={handleSaveToggle}
              />
            ))}
          </div>
        ) : (
          <div className="text-gray-400 text-center py-8 lg:py-12 bg-gray-800 rounded-lg">
            <p className="px-4">
              No saved companies yet. Start browsing and save companies you're interested in!
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
